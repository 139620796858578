import React, { useState, useEffect, useContext } from "react";
import { adminHost, authHost, host } from "../../http";

import icons from "../../assets/icons/coin_map.json";
import "react-datepicker/dist/react-datepicker.css";
import SelectCust from "../SelectCust";
import Alert from "../modals/Alert";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { Calendar } from "../ui/Calendar/Calendar";

const ControlS = observer(({ options, setSearch }) => {
  const { admin } = useContext(Context); 
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [defcoins, setDefCoins] = useState(null);
  const [deffee, setDefFee] = useState(null);
  const [pairFrom, setPairFrom] = useState(null);
  const [pairTo, setPairTo] = useState(null);
  const [fixed, setFixed] = useState(null);
  const [fixedCheck, setFixedCheck] = useState(true);
  const [float, setFloat] = useState(null);
  const [floatCheck, setFloatCheck] = useState(true);
  const [risk, setRisk] = useState(null);
  const [riskCheck, setRiskCheck] = useState(true);
  const [defaultAmount, setDefAmount] = useState(null);

  const [fromIsOpen, setFromIsOpen] = useState(false);
  const [toIsOpen, setToIsOpen] = useState(false);

  useEffect(() => {
    if (!admin.options || pairFrom || pairTo) return;
    host
      .get("/constant/api/json/default_pairs/")
      .then((response) => {
        setDefCoins(response?.data?.results);
        let {to, from, amount} = response?.data.value;
        
        if(amount) setDefAmount(amount);
        if(from) setPairFrom(from);
        if(to) setPairTo(to);
        })
      .catch((e) => console.error(e));
    adminHost
      .get("/api/feesettings/")
      .then((response) => {
        setDefFee(response?.data?.results);
        setFixed(response?.data?.results?.find((el) => el?.fee_type === "fix")?.fee_percentage);
        setFloat(response?.data?.results?.find((el) => el?.fee_type === "float")?.fee_percentage);
        setRisk(response?.data?.results?.find((el) => el?.fee_type === "risk")?.fee_percentage);

      })
      .catch((e) => console.error(e));
  }, [admin.options]);

  useEffect(() => {
    if (+fixed <= 0 || isNaN(fixed)) {
      setFixedCheck(false);
    } else {
      setFixedCheck(true);
    }
    if (+float <= 0 || isNaN(float)) {
      setFloatCheck(false);
    } else {
      setFloatCheck(true);
    }
    if (+risk <= 0 || isNaN(risk)) {
      setRiskCheck(false);
    } else {
      setRiskCheck(true);
    }
  }, [fixed, float, risk]);

  const saveChanges = () => {
    console.log(pairFrom, pairTo)
    const data = {
      default_coins: {
        // coin: [defAmount, pairFrom.pair, pairTo.pair].join("/"),
        
          from: pairFrom,
          to: pairTo,
          amount: defaultAmount
        
      },
      fee: {
        fixed: {
          fee_type: "fix",
          fee_percentage: fixedCheck ? fixed : 0,
        },
        float: {
          fee_type: "float",
          fee_percentage: floatCheck ? float : 0,
        },
        risk: {
          fee_type: "risk",
          fee_percentage: riskCheck ? risk : 0,
        }
      },
    };

    adminHost
      .patch(`/constant/api/json/default_pairs/`, {value: data.default_coins})
      .then((response) => {console.log(response)})
      .catch((e) => console.error(e));
    adminHost
      .patch(`/api/feesettings/${deffee?.find((el) => el?.fee_type === "fix")?.id}/`, data.fee.fixed)
      .then((response) => {})
      .catch((e) => console.error(e));
    adminHost
      .patch(`/api/feesettings/${deffee?.find((el) => el?.fee_type === "float")?.id}/`, data.fee.float)
      .then((response) => {})
      .catch((e) => console.error(e));
    adminHost
      .patch(`/api/feesettings/${deffee?.find((el) => el?.fee_type === "risk")?.id}/`, data.fee.risk)
      .then((response) => {})
      .catch((e) => console.error(e));
  };
  return (
    <div className="right_block">
      <div className="content_block" style={{ height: "100%" }}>
        <div className="general-half">
          <h1 className="general-label">Control</h1>
          <button className="general-btn">Transfer all to master</button>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div className="changeblock_placeholder2">
              <div className="yousend">Fee, % (fixed)</div>
              <input placeholder="1" className="placeholder_price" value={fixed} type="text" onChange={(e) => setFixed(e.target.value)} />
              <label class="container">
                <input
                  type="checkbox"
                  checked={fixedCheck}
                  onChange={() => {
                    setFixedCheck(!fixedCheck);
                  }}
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div className="changeblock_placeholder2">
              <div className="yousend">Fee, % (float)</div>
              <input placeholder="1.5" className="placeholder_price" value={float} type="text" onChange={(e) => setFloat(e.target.value)} />
              <label class="container">
                <input
                  type="checkbox"
                  checked={floatCheck}
                  onChange={() => {
                    setFloatCheck(!floatCheck);
                  }}
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div className="changeblock_placeholder2">
              <div className="yousend">Risk Score, %</div>
              <input placeholder="1.5" className="placeholder_price" value={risk} type="text" onChange={(e) => setRisk(e.target.value)} />
              <label class="container">
                <input
                  type="checkbox"
                  checked={riskCheck}
                  onChange={() => {
                    setRiskCheck(!riskCheck);
                  }}
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "20px", gap: "10px" }}>
            <div className="changeblock_placeholder2">
              <div className="yousend">Default Amount</div>
              <input placeholder="0.1" className="placeholder_price" value={defaultAmount} type="text" onChange={(e) => setDefAmount(e.target.value)} />
            </div>
            <div className="changeblock_placeholder2">
              <div
                className="placeholder_coin"
                style={{ position: "relative", boxSizing: "border-box", maxWidth: "100%" }}
                onClick={() => {
                  setFromIsOpen(!fromIsOpen);
                  setToIsOpen(false);
                }}
              >
                <div className="css-gebwvy-control">
                  <div className="css-ValueContainer">
                    <div className="css-singleValue">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {!pairFrom ? (
                          <span style={{ marginLeft: "5px", fontFamily: "Montserrat", fontWeight: 500, color: "#333" }}>BTC</span>
                        ) : (
                          <>
                            <img loading="lazy" src={icons[pairFrom?.token]} width={30} style={{ borderRadius: "5px" }} className="coin_icon" alt="" />
                            <span style={{ marginLeft: "5px", fontFamily: "Montserrat", fontWeight: 600 }}>
                              {pairFrom?.token}
                              <br></br>
                              <span style={{ fontSize: "10px", color: "rgb(136, 136, 136)", fontWeight: 400 }}>{pairFrom.network}</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="css-IndicatorsContainer">
                    <div className="css-indicatorContainer">
                      <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-Svg">
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="changeblock_placeholder2">
              <div
                className="placeholder_coin"
                style={{ position: "relative", boxSizing: "border-box", maxWidth: "100%" }}
                onClick={() => {
                  setToIsOpen(!toIsOpen);
                  setFromIsOpen(false);
                }}
              >
                <div className="css-gebwvy-control" onClick={() => {}}>
                  <div className="css-ValueContainer">
                    <div className="css-singleValue">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {!pairTo ? (
                          <span style={{ marginLeft: "5px", fontFamily: "Montserrat", fontWeight: 500, color: "#333" }}>BTC</span>
                        ) : (
                          <>
                            <img loading="lazy" src={icons[pairTo?.token]} width={30} style={{ borderRadius: "5px" }} className="coin_icon" alt="" />
                            <span style={{ marginLeft: "5px", fontWeight: 600, fontFamily: "Montserrat" }}>
                              {pairTo?.token}
                              <br></br>
                              <span style={{ fontSize: "10px", color: "rgb(136, 136, 136)", fontWeight: 400 }}>{pairTo.network}</span>
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="css-IndicatorsContainer">
                    <div className="css-indicatorContainer">
                      <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-Svg">
                        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
            <div style={{ position: "relative" }}>
              <SelectCust
                          isOpen={fromIsOpen}
                          options={options}
                          setIsOpen={setFromIsOpen}
                          selectedOption={pairFrom}
                          setSelectedOption={setPairFrom}
                          // updatePrice={setPriceTrigger}
                          // setSearch={setCustSearch}
                        />
            </div>
            <div style={{ position: "relative" }}>
              <SelectCust
                          isOpen={toIsOpen}
                          options={options}
                          setIsOpen={setToIsOpen}
                          selectedOption={pairTo}
                          setSelectedOption={setPairTo}
                          // updatePrice={setPriceTrigger}
                          // setSearch={setCustSearch}
                        />
            </div>
          <button
            className="general-btn"
            onClick={() => {
              saveChanges();
              setAlertIsOpen(true);
            }}
          >
            Save changes
          </button>
        </div>
      </div>
      <style jsx>{`
        .general-half {
          // width: 50%;
          display: flex;
          flex-direction: column;
        }
        .general-label {
          font-size: 1.5rem;
          font-weight: 500;
        }
        .general-btn {
          font-family: Montserrat;
          font-size: 1rem;
          font-weight: 500;
          padding: 10px 20px;
          border-radius: 0.6rem;
          border: none;
          background-color: #27ad61;
          color: #fff;
          margin: 20px 0 0 0;
          max-width: 300px;
          cursor: pointer;
        }
        .container {
          display: block;
          position: relative;
          padding-left: 45px;
          margin: 0 5px 0 10px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 25%;
          left: 0;
          height: 35px;
          width: 35px;
          border-radius: 5px;
          background-color: #eee;
        }

        /* On mouse-over, add a grey background color */
        .container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .container input:checked ~ .checkmark {
          background-color: #27ad61;
        }
        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .container .checkmark:after {
          left: 11px;
          top: 7px;
          width: 9px;
          height: 13px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      `}</style>
      {alertIsOpen ? <Alert onClose={() => setAlertIsOpen(false)} text="Saved!" /> : null}
    </div>
  );
});

export default ControlS;
