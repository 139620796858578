import React, { useState } from "react";
import SelectWithPagination from "./SelectWithPagination";
import { adminHost } from "../../http";

const CurrencyFilter = ({ selectedOption, setSelectedOption }) => {
  const [page, setPage] = useState(1)
  console.log({page})

  return (
    <SelectWithPagination
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      placeholder="Currency"
      page={page}
      setPage={setPage}
      onSelect={(option) => setSelectedOption(option.token)}
      fetcher={(params) => adminHost.get(`coin/api/coin`, params)}
      itemComponent={(option) => `${option.token}`}
      isSelected={(option) => option.token === selectedOption}
    />
  );
};

export default CurrencyFilter;