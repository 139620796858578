import React, { useState } from "react";
import SelectWithPagination from "./SelectWithPagination";
import { adminHost } from "../../http";

const NetworkFilter = ({ selectedOption, setSelectedOption }) => {
  const [page, setPage] = useState(1)
  console.log({page})
  return (
    <SelectWithPagination
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      placeholder="Network"
      page={page}
      setPage={setPage}
      onSelect={(option) => setSelectedOption(option.title)}
      fetcher={(params) => adminHost.get(`/network/api/network/`, params)}
      itemComponent={(option) => `${option.title}`}
      isSelected={(option) => option.title === selectedOption}
    />
  );
};

export default NetworkFilter;