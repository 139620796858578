import React, { useCallback, useEffect, useRef, useState } from "react";
import Close from "../../assets/images/close.svg";
import Check from "../../assets/images/galkaOK.svg";
import SeeMore from "../../assets/images/seemore.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { DotLoader } from "react-spinners";

const SelectWithPagination = ({
  fetcher,
  selectedOption,
  setSelectedOption,
  itemComponent,
  onSelect,
  placeholder,
  isSelected,
  page,
  setPage,
}) => {
  const [options, setOptions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const listRef = useRef(null);
  const component_key = placeholder || crypto.randomUUID();
  const fetchOptions = async (pageNumber, query) => {
    let result = options;
    try {
      const response = await fetcher({
        params: { page: pageNumber, page_size: 20, search: query },
      });
      const newOptions = response.data.results;

      result = newOptions;
      setHasMore(!!response.data.next);
    } catch (error) {
      console.error("Error fetching coins:", error);
    } finally {
      setLoading(false);
    }
    setOptions(result);
    return result;
  };

  const loadMoreOptions = (prevPage) => {
    console.log({ hasMore, page: prevPage + 1, loading });
    if (hasMore && !loading) {
      setPage(prevPage + 1);
      fetchOptions(prevPage + 1, searchQuery).then((res) =>
        setOptions((prev) => [...prev, ...res])
      );
      // if(listRef.current) listRef.current.scrollTo(0, 0)
    }
  };

  const handleOnSearch = (query) => {
    setOptions([]);
    setPage(1);
    setSearchQuery(query);
    setSelectedOption();
    fetchOptions(1, query)
      .then((res) => {
        return res;
      })
      .then(setOptions);
  };

  useEffect(() => {
    if (!options.length) fetchOptions(page, searchQuery).then(setOptions);
  }, []);

  return (
    <>
      <input
        type="text"
        className="select"
        placeholder={placeholder}
        value={searchQuery || selectedOption}
        onChange={(e) => handleOnSearch(e.target.value)}
        style={{
          background: "none",
          border: "none",
          padding: "0",
          margin: "0",
          width: "100%",
          height: "20px",
        }}
      />{" "}
      {selectedOption ? (
        <img
          src={Close}
          width={12}
          className="drop-arrow"
          style={{ opacity: 0.5, cursor: "pointer" }}
          onClick={() => {
            setSelectedOption("");
            setSearchQuery("");
            setPage(1);
            fetchOptions(1);
          }}
          alt=""
        />
      ) : (
        <img src={SeeMore} width={12} className="drop-arrow" alt="" />
      )}
      <ul
        ref={listRef}
        className="displaynone scrollableDiv"
        id={`scrollableDiv-${component_key}`}
      >
        <InfiniteScroll
          key={placeholder}
          dataLength={page * 20}
          next={(props) => {
            loadMoreOptions(page);
          }}
          hasMore={hasMore}
          scrollableTarget={`scrollableDiv-${component_key}`}
        >
          {options.length ? (
            options.map((option, index) => {
              return (
                <li
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (onSelect) onSelect(option);
                    setSearchQuery("");
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', paddingRight: '4px' }}>
                    {itemComponent(option)}{" "}
                    {isSelected(option) && (
                      <img
                        src={Check}
                        width={12}
                        // className="drop-arrow"
                        style={{ opacity: 0.5, cursor: "pointer" }}
                        onClick={() => setSelectedOption("")}
                        alt=""
                      />
                    )}
                  </div>
                </li>
              );
            })
          ) : (
            <li>Empty list</li>
          )}
        </InfiniteScroll>
      </ul>
    </>
  );
};

export default SelectWithPagination;
