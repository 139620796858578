import { makeAutoObservable } from "mobx";

export default class AdminStore {
  constructor() {
    this._isAuth = false;
    this._user = {};
    this._activeScreen = "swaps";
    this._options = []
    makeAutoObservable(this);

    const storedActiveScreen = localStorage.getItem("activeAdminScreen");
    const storedLogged = document.cookie.split("; ").find(row => row.startsWith("admin-token="))?.split("=")[1];
    if (storedLogged) {
      this._isAuth = true;
    } else {
      this._isAuth = false;
    }
    if (storedActiveScreen) {
      this._activeScreen = JSON.parse(storedActiveScreen);
    } else {
      this._activeScreen = "swaps";
      this._updateLocalStorage();
    }
  }
  _updateLocalStorage() {
    if (this._activeScreen !== null) {
      localStorage.setItem("activeAdminScreen", JSON.stringify(this._activeScreen));
    }
  }

  setIsAuth(bool) {
    this._isAuth = bool;
  }

  setOptions(options) {
    console.log(options)
    this._options = options
  }

  setUser(user) {
    this._user = user;
  }

  setActiveScreen(screen) {
    this._activeScreen = screen;
    this._updateLocalStorage();
  }

  get isAuth() {
    return this._isAuth;
  }

  get options() {
    return this._options;
  }

  get user() {
    return this._user;
  }

  get screen() {
    return this._activeScreen;
  }
  logout() {
    document.cookie = "admin-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; 
    document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.setIsAuth(false);
  }
}
