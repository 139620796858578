import * as React from "react";

import DatePicker from "react-datepicker";

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  onChange,
  onConfirm,
  ...props
}) {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(null);
  let calendarRef = React.useRef(null);
  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (onChange) onChange(start, end);
  };

  const handleConfirm = (start, end) => {
    if (start && end) {
      const formattedStart = start ? start.toISOString().split("T")[0] : null;
      const formattedEnd = end ? end.toISOString().split("T")[0] : null;
      onConfirm(formattedStart, formattedEnd);
      if (calendarRef) calendarRef.setOpen(false);
    }
  };
  return (
    <DatePicker
      ref={(r) => (calendarRef = r)}
      selected={startDate}
    
      onChange={handleChange}
      startDate={startDate}
      endDate={endDate}
      shouldCloseOnSelect={false}
      calendarContainer={({ children, className }) => (
        <div style={{ width: "min-content" }} className={className}>
          {children}

          {startDate && endDate && (
            <div style={{ width: "100%", display: "flex" }}>
              <button
                onClick={() => handleConfirm(startDate, endDate)}
                style={{
                  fontSize: "12px",
                  margin: "auto",
                  marginBlock: "12px",
                }}
                className="general-btn"
              >
                Send
              </button>
            </div>
          )}
        </div>
      )}
      selectsRange
      customInput={<button className="general-btn">DROP ANALYTIC</button>}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
